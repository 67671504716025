<template>
  <div class="mobile-banner">
    <!-- 弹幕 -->
    <div v-if="danmuList.length" class="barrage">
      <DanMuVue :list="danmuList" :page="1" />
    </div>
    <div class="banner">
      <swiper
        :options="swiperOptionTop"
        class="banner-carousel"
        ref="swiperBanner"
        @slideChange="onChange"
      >
        <swiper-slide v-for="(item, index) in list" :key="index">
          <template v-if="index === 0">
            <!-- 搜索 -->
            <div class="search-box" :class="theme == 'holiday' ? 'system-color-bg-color' : ''">
              <SearchInput
                ref="searchInputRef"
                v-model="search"
                only-search
                @focus="onFocus"
                @blur="onBlur"
                @search="toSearch"
              />
              <!-- <input
                type="search"
                class="search"
                v-model="search"
                placeholder="请输入搜索内容"
              />
              <button @click="toSearch" class="search-btn">搜索</button> -->
            </div>
            <template>
              <div class="welcome-title2" v-if="theme == 'holiday'">
                <img
                  class="spring-img"
                  src="../../../../assets/img/spring/spring-title.png"
                  alt=""
                />
              </div>
              <div class="welcome-title" v-else>
                <div class="title">欢迎来到北京人民艺术剧院</div>
                <div class="title-en">
                  Welcome To Beijing People's Art Theatre
                </div>
              </div>
            </template>
          </template>
          <c-image
            :style="{
              backgroundImage: `url(${item.image})`,
            }"
            :src="item.imageWorld"
            class="banner-img"
            @click="handleDetail(item)"
          />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import SearchInput from "@/components/pc/SearchInput.vue";
import DanMuVue from "./DanMu.vue";

export default {
  components: {
    SearchInput,
    DanMuVue,
    swiper,
    swiperSlide,
  },
  data () {
    return {
      search: "",
      swiperOptionTop: {
        spaceBetween: 0,
        autoplay: {
          //自动轮播
          delay: 300000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination", //与slot="pagination"处 class 一致
          clickable: true, //轮播按钮支持点击
        },
      },
    };
  },
  props: {
    homeDataAll: {
      type: Object,
      default: () => { },
    },
    list: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    backgroundPosition: String,
  },
  computed: {
    danmuList () {
      let arr = [];
      arr = [
        ...(this.homeDataAll?.notices || []),
        ...(this.homeDataAll?.surveys || []),
      ];
      return arr;
    },
    theme () {
      return this.$store.state.systemTheme;
    }
  },
  mounted () { },
  methods: {
    //跳转搜索页
    toSearch () {
      const { href } = this.$router.resolve({
        path: "/search/index",
        query: {
          search: this.search,
        },
      });
      window.open(href, "_self");
    },
    handleDetail (item) {
      if (!item.targetId) return;
      if (item.targetType === "Repertoire") {
        window.open(`#/performance/detail/${item.targetId}`, "_self");
      } else if (item.targetType === "Article") {
        window.open(`#/ArtsInformation/detail?id=${item.targetId}`, "_self");
      } else if (item.targetType === "Digital") {
        window.open(
          `#/theatreMuseum/digitalDetail?id=${item.targetId}`,
          "_self"
        );
      }
    },
    onFocus () {
      this.$refs.swiperBanner.swiper.autoplay.stop();
    },
    onBlur () {
      this.$refs.swiperBanner.swiper.autoplay.start();
    },
    onChange () {
      this.$refs.searchInputRef[0].blur();
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/assets/style/mixin.less");
@font-face {
  font-family: "Source Han Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../../../../assets/font/SOURCEHANSANSCN-MEDIUM.OTF")
    format("opentype");
}
.welcome-title2 {
  position: absolute;
  top: 120/75rem;
  left: 25/75rem;
  z-index: 9;
}
.spring-img {
  width: 420/75rem;
  object-fit: contain;
}
.mobile-banner {
  width: 100%;
  position: relative;
  .barrage {
    position: relative;
    width: 100%;
    height: 56/75rem;
    background: #21424b;
    z-index: 9;
    overflow: hidden;
  }
  .search-box {
    position: absolute;
    top: 24/75rem;
    left: 4%;
    width: 92%;
    height: 80/75rem;
    background: #ffffff;
    border-radius: 8/75rem;
    z-index: 9;
    display: flex;
    justify-content: space-between;
    .search {
      flex: 1;
      height: 100%;
      font-size: 24/75rem;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #717171;
      line-height: 36/75rem;
      padding-left: 30/75rem;
      border: 0; // 去除未选中状态边框
      outline: none; // 去除选中状态边框
      background-color: rgba(0, 0, 0, 0); // 透明背景
    }
    .search-btn {
      width: 104/75rem;
      height: 64/75rem;
      margin: 8/75rem;
      background: #f9680d;
      border-radius: 4/75rem;
      font-size: 24/75rem;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ffffff;
      line-height: 36/75rem;
      border: none;
      cursor: pointer;
    }
  }
  .welcome-title {
    position: absolute;
    top: 147/75rem;
    left: 126/75rem;
    z-index: 9;
    .title {
      font-size: 40/75rem;
      font-family: "Source Han Sans", sans-serif;
      // font-weight: 800;
      color: #ffffff;
      line-height: 84/75rem;
    }
    .title-en {
      font-size: 25/75rem;
      font-family: "Source Han Sans", sans-serif;
      font-weight: 400;
      color: #ffffff;
      line-height: 45/75rem;
    }
  }
  .banner {
    height: 390/75rem;
  }
  .banner-img {
    height: 100%;
    width: 100%;
    background-size: cover;
  }
}
</style>
<style lang="less">
.banner-carousel {
  height: 390/75rem;
  .swiper-pagination {
    .swiper-pagination-bullet {
      width: 32/75rem;
      height: 6/75rem;
      border-radius: 3/75rem;
      background-color: #ffffff;
      opacity: 0.6;
      &.swiper-pagination-bullet-active {
        background-color: #f9680d !important;
        opacity: 1;
      }
    }
  }
}
</style>
