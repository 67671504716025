<template>
  <div class="home-page">
    <Header v-show="currentPage <= list.length" class="home-header" isWhite />

    <div class="pageTips" :class="(theme == 'holiday' && currentPage != 1) ? 'system-color-text' : ''" v-if="currentPage < list.length + 1">
      <div>{{ currentPage > 9 ? currentPage : "0" + currentPage }}</div>
      <div class="center">\</div>
      <div>{{ list.length > 9 ? list.length : "0" + list.length }}</div>
    </div>
    <!-- 弹幕 -->
    <div class="barrage">
      <DanMuVue :list="danmuList" :page="currentPage" />
    </div>
    <!-- 在线购票 -->
    <div class="to-coupon-buy" @click="toBuy()">
      <img class="img" src="@/assets/img/home/new-center-buy-icon.png" />
      <div class="coupon-title">在线购票</div>
    </div>
    <template>
      <div
        v-for="(item, index) in list"
        :class="`page-full page${index + 1}`"
        :key="item.id"
        :style="{
          backgroundImage: `url(${item.image})`,
        }"
        :ref="`banner-${index}`"
        @click="handleDetail(item)"
      >
        <c-image
          v-if="index !== 0 && item.imageWorld"
          class="page-bg"
          :src="item.imageWorld"
        />
        <template v-if="index === 0">
          <!-- 搜索页 -->
          <div class="page1-content">
            <template>
              <div class="page1-content-title" v-if="theme == 'holiday'">
                <img src="../../../../assets/img/spring/spring-title.png" class="title" style="object-fit: contain;" alt="">
              </div>
              <div class="page1-content-title" v-else>
                <div class="title">欢迎来到北京人民艺术剧院</div>
                <div class="title-en">
                  Welcome To Beijing People's Art Theatre
                </div>
              </div>
            </template>
            <div class="search-weather">
              <div class="weather">
                <div class="date">
                  {{ dateNow }} {{ weekDate }} {{ `(农历${nongli})` }}
                </div>
                <div v-if="weatherInfo.length">
                  北京市 {{ weatherInfo[0].daytemp }}℃
                  {{ weatherInfo[0].dayweather }}
                </div>
              </div>
              <div class="search-box">
                <input
                  type="search"
                  class="search"
                  v-model="search"
                  placeholder="请输入搜索内容"
                  @keydown.enter="toSearch"
                />
                <button
                  @click="toSearch"
                  class="search-btn"
                  :class="theme == 'holiday' ? 'system-color-bg' : ''"
                >
                  搜索
                </button>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
    <template>
      <div v-if="inited" class="pages-content">
        <!-- 人艺资讯 -->
        <NewCenterVue :list="centerList" ref="newRef" />
        <!-- 人艺演出 -->
        <Performance :list="repertoires" />
        <!-- 人艺视界 -->
        <ArtslectureVue
          :list="videoLeftList"
          :listRight="videoRightList"
          v-if="videoLeftList.length"
        />
        <!-- 剧目典藏 -->
        <RepertoireCollection />
        <!-- 人艺人 -->
        <Artist :list="entertainersList" />
        <!-- 人艺活动 -->
        <Activity :footerDataList="footerDataList" />
        <PageFooter />
      </div>
    </template>
    <c-image
      v-show="currentPage < list.length"
      :src="require('@/assets/img/home/down.png')"
      class="down-img"
      @click="toBottom"
    />
    <div
      v-show="currentPage > 1"
      class="top-img"
      @click="toTop"
      :class="theme == 'holiday' ? 'system-color-bg-y' : ''"
    >
      TOP
    </div>
  </div>
</template>
<script>
import axios from "axios";
import dayjs from "dayjs";
import { getLunar } from "chinese-lunar-calendar";
import DanMuVue from "./DanMu.vue";
import NewCenterVue from "./NewCenter.vue";
import Performance from "./Performance.vue";
import ArtslectureVue from "./Artslecture.vue";
import RepertoireCollection from "./RepertoireCollection.vue";
import Artist from "./Artist.vue";
import Activity from "./Activity.vue";
import Header from "@/components/pc/Header";

export default {
  name: "Home",
  props: {
    list: { type: Array, default: () => [] },
    homeDataAll: {
      type: Object,
      default: () => { },
    },
    inited: Boolean,
  },
  components: {
    Header,
    DanMuVue,
    NewCenterVue,
    Performance,
    ArtslectureVue,
    RepertoireCollection,
    Artist,
    Activity,
  },
  computed: {
    centerList () {
      return {
        referralLeft: this.homeDataAll?.referralLeft || [],
        referralRight: this.homeDataAll?.referralRight || [],
        referralTop: this.homeDataAll?.referralTop || [],
      };
    },
    videoLeftList () {
      return this.homeDataAll?.videoLeft || [];
    },
    videoRightList () {
      return this.homeDataAll?.videoRight || [];
    },
    entertainersList () {
      return this.homeDataAll?.entertainers || {};
    },
    repertoireCollectionList () {
      return this.homeDataAll?.recommendRepertoires || [];
    },
    footerDataList () {
      return {
        activity: this.homeDataAll?.activity || [],
        dramaGuide: this.homeDataAll?.dramaGuide || {},
        museums: this.homeDataAll?.museums || [],
        read: this.homeDataAll?.read || [],
      };
    },
    danmuList () {
      let arr = [];
      arr = [
        ...(this.homeDataAll?.notices || []),
        ...(this.homeDataAll?.surveys || []),
      ];
      return arr;
    },
    repertoires () {
      return this.homeDataAll?.repertoires || [];
    },
    theme () {
      return this.$store.state.systemTheme;
    }
  },
  data () {
    return {
      dynamicSlotName: "page",
      isV: true,
      currentPage: 1,
      bgArr: ["#4FD7F9", "orange", "pink", "green"],
      scrollTop: 0,
      weatherInfo: [],
      dateNow: dayjs().startOf("day").format("YYYY年MM月DD日  "),
      weekDate: "",
      nongli: "",
      search: "",
      // bgArr: [{
      //   isBg: true,
      //   src: require('@/assets/....')
      // }, 'orange', 'pink', 'green']
      elem: null,
    };
  },
  methods: {
    //跳转搜索页
    toSearch () {
      const { href } = this.$router.resolve({
        path: "/search/index",
        query: {
          search: this.search,
        },
      });
      window.open(href, "_blank");
    },
    //滚动事件
    handleScroll (e) {
      const classList = [...(e.target.classList || [])];
      if (classList.includes("home-page")) {
        // this.scrollTop = e.target.scrollTop;
        this.currentPage =
          Math.ceil(e.target.scrollTop / window.innerHeight) || 1;
      }
    },
    getWeather () {
      axios
        .get(
          "https://restapi.amap.com/v3/weather/weatherInfo?key=ea58d651870d35abce047557fcadea5e&city=110000&extensions=all"
        )
        .then((res) => {
          this.weatherInfo = res.data.forecasts[0]?.casts || [];
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    toBuy () {
      window.open(process.env.VUE_APP_TICKET_URL, "_blank");
    },
    toBottom () {
      this.$refs.newRef.$el.scrollIntoView({
        behavior: "instant",
        block: "start",
      });
    },
    toTop () {
      this.$refs["banner-0"][0].scrollIntoView({
        behavior: "instant",
        block: "start",
      });
    },
    handleDetail (item) {
      if (!item.targetId) return;
      if (item.targetType === "Repertoire") {
        window.open(`#/performance/detail/${item.targetId}`, "_blank");
      } else if (item.targetType === "Article") {
        window.open(`#/ArtsInformation/detail?id=${item.targetId}`, "_blank");
      } else if (item.targetType === "Digital") {
        window.open(
          `#/theatreMuseum/digitalDetail?id=${item.targetId}`,
          "_blank"
        );
      }
    },
    getLunar () {
      let data = getLunar(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        new Date().getDate()
      );
      this.nongli = data.dateStr;
    },
  },
  mounted () {
    this.elem = document.querySelector(".home-page");
    // 向页面添加股东事件
    this.elem.addEventListener("scroll", this.handleScroll, true);
    this.getWeather();
    var datas = dayjs().day();
    var week = ["日", "一", "二", "三", "四", "五", "六"];
    this.weekDate = "星期" + week[datas];
    this.getLunar();
  },
  beforeDestroy () {
    //离开这个界面之后，删除滚动事件，不然容易除bug
    this.elem.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="less" scoped>
@keyframes flip {
  0% {
    bottom: 2.6042vw;
  }
  50% {
    bottom: 1.5625vw;
  }
  100% {
    bottom: 2.6042vw;
  }
}
@font-face {
  font-family: "Source Han Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../../../../assets/font/SOURCEHANSANSCN-MEDIUM.OTF")
    format("opentype");
}
.home-page {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  .home-header {
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 1000;
    border-bottom: 0.0521vw solid rgba(255, 255, 255, 0.2);
  }
  .pageTips {
    position: absolute;
    z-index: 2;
    right: 3.6979vw;
    top: 50%;
    font-size: 0.9375vw;
    font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
    color: #ffffff;
    font-weight: bold;
    line-height: 0.9375vw;
    .center {
      margin: 0.2604vw 0;
      text-align: center;
      transform: skewX(45deg) skewY(-35deg);
    }
  }

  .barrage {
    position: absolute;
    top: 4.9479vw;
    left: 0;
    width: 100%;
    height: 5.7292vw;
    overflow: hidden;
    z-index: 1;
  }
  .down-img {
    width: 2.5vw;
    height: 2.5vw;
    position: fixed;
    bottom: 2.6042vw;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    z-index: 3;
    overflow: visible;
    animation: flip 1.5s ease-in-out infinite;
    &::after {
      content: "人艺资讯";
      display: inline-block;
      word-break: keep-all;
      font-size: 0.7292vw;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      position: absolute;
      top: 2.8125vw;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .top-img {
    width: 2.0833vw;
    height: 2.0833vw;
    box-sizing: border-box;
    padding-top: 0.9896vw;
    background-color: #21424b;
    color: #ffffff;
    font-size: 0.625vw;
    text-align: center;
    border-radius: 0.2083vw;
    position: fixed;
    bottom: 3.8021vw;
    right: 2.0833vw;
    cursor: pointer;
    z-index: 3;
    &::after {
      content: "";
      width: 0;
      height: 0;
      border: 0.3125vw solid transparent;
      border-bottom-color: #ffffff;
      position: absolute;
      top: 0.1563vw;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .to-coupon-buy {
    position: fixed;
    right: 2.0833vw;
    top: 11.4583vw;
    width: 4.1667vw;
    height: 4.1667vw;
    background: url("~@/assets/img/home/buy-bg.png") no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    cursor: pointer;
    z-index: 20;

    .img {
      position: absolute;
      top: 0.2083vw;
      left: 0.6771vw;
      width: 2.6042vw;
      height: 2.6042vw;
    }
    .coupon-title {
      font-size: 0.625vw;
      font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
      font-weight: 800;
      color: #1f1002;
      line-height: 1.0417vw;
      position: absolute;
      bottom: 0.9375vw;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      text-align: center;
    }
  }
  .page-full {
    position: relative;
    min-width: 72.9167vw;
    min-height: 56.25vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    .page-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    &.page1 {
      // padding-top: 110px;
      .page1-content {
        position: relative;
        text-align: left;
        padding-left: 13.5417vw;
        .page1-content-title {
          .title {
            padding-top: 8.8542vw;
            font-size: 3.3333vw;
            font-family: "Source Han Sans", sans-serif;
            // font-weight: 800;
            color: #ffffff;
            line-height: 4.6875vw;
          }
          .title-en {
            font-size: 1.875vw;
            font-family: "Source Han Sans", sans-serif;
            font-weight: 800;
            color: #ffffff;
            line-height: 2.8125vw;
          }
        }
        .search-weather {
          word-spacing: 0.5208vw;
          font-family: "Source Han Sans", sans-serif;
          .weather {
            padding-top: 2.0833vw;
            display: flex;
            align-items: center;
            font-size: 1.1458vw;
            font-weight: 400;
            color: #ffffff;
            line-height: 1.875vw;
            .date {
              margin-right: 2.0833vw;
            }
          }
          .search-box {
            margin-top: 0.8333vw;
            width: 33.8542vw;
            height: 2.3438vw;
            background: #e7edf4;
            border-radius: 0.4167vw;
            border: 0.0521vw solid #dfdfdf;
            background: #e7edf4;
            display: flex;
            justify-content: space-between;
            .search {
              flex: 1;
              height: 100%;
              font-size: 0.9375vw;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #717171;
              line-height: 1.4063vw;
              padding-left: 1.6667vw;
              border: 0; // 去除未选中状态边框
              outline: none; // 去除选中状态边框
              background-color: rgba(0, 0, 0, 0); // 透明背景
            }
            .search-btn {
              width: 5.2083vw;
              height: 1.8229vw;
              margin: 0.2604vw;
              background: #f9680d;
              border-radius: 0.4167vw;
              font-size: 0.9375vw;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #ffffff;
              line-height: 1.8229vw;
              border: none;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .pages-content {
    position: relative;
    min-width: 72.9167vw;
    min-height: 100vh;
    z-index: 2;
    ::v-deep(.header) {
      height: auto;
    }
  }
}
</style>
<style lang="less">
.artist-carousel {
  .el-carousel__arrow {
    display: none !important;
  }
  .el-carousel__indicators {
    .el-carousel__indicator--horizontal {
      padding: 0;
      &.is-active {
        .el-carousel__button {
          background-color: #d9001b !important;
        }
      }
    }
  }
}
.home-page .content-swiper .arrow {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -1.1979vw;
  width: 100%;
  .left {
    cursor: pointer;
    width: 4.0104vw;
    height: 1.875vw;
  }
  .right {
    cursor: pointer;
    width: 4.0104vw;
    height: 1.875vw;
  }
}
.home-page .content-title-bg {
  position: absolute;
  writing-mode: vertical-lr;
  top: 3.6458vw;
  left: 4.9479vw;
  font-weight: bold;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  text-align: left;
  .title {
    font-size: 5.2083vw;
    line-height: 5.2083vw;
    white-space: nowrap;
  }
  .tips {
    position: absolute;
    top: 0.4688vw;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.625vw;
    line-height: 0.625vw;
    white-space: nowrap;
  }
}
</style>
